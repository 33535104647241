import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import NewsList from "../components/news-list";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query NewsCategoryQuery($skip: Int!, $limit: Int!, $id: String!) {
    newsLanding: sanityNewsLanding {
      title
      themePurple
    }

    currentCat: sanityNewsCategory(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawDescription
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    news: allSanityNews(
      sort: { fields: [publicationDate], order: DESC }
      filter: {categories: {elemMatch: {newsCategory: {id: {eq: $id}}}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          author {
            name
          }
          publicationDate
          thumbnail {
            ...SanityImage
            alt
          }
          categories {
            _key
            newsCategory {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }

    newsAll: allSanityNews(
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          categories {
            newsCategory {
              id
            }
          }
        }
      }
    }

    newsCats: allSanityNewsCategory(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const NewsCategoryTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { currentPage, numPages } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const newsLanding = (data || {}).newsLanding;
  const currentCat = (data || {}).currentCat;

  const news = (data || {}).news.edges;
  const newsAll = (data || {}).newsAll.edges;

  const newsCats = (data || {}).newsCats.edges.filter(cat => {
    const newsInCat = newsAll.filter(post => (
      post.node.categories.map(({newsCategory}) => newsCategory.id).includes(cat.node.id)
    ));
    return newsInCat.length > 0;
  });

  const title = currentCat.seo?.title || currentCat.title;
  const description = currentCat.seo?.description || toPlainText(currentCat._rawDescription);
  const image = currentCat.seo?.thumbnail;
  const autoSiteTitle = !currentCat.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={newsLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {news && (<NewsList news={news} cats={newsCats} landingTitle={newsLanding.title} currentCat={currentCat} currentPage={currentPage} numPages={numPages} />)}
    </Layout>
  );
};

export default NewsCategoryTemplate;
